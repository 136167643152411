import CommonStyles from "./CommonStyles"

const loginCardStyles = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100dvh',
        bgcolor: '#e7e7e7',
    },
    video: {
        position: 'fixed',
        zIndex: -1,
        width: '100%'
    },
    card: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '8px',
        boxShadow: 20
    },
    cardContent: {
        // mx: 2,
        p: '40px',
        // py: '40px',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        ...CommonStyles.scrollStyle,
    },
    loginTypography: {
        fontWeight: 700,
    },
    textField: {
        mt: 1,
        mb: 2,
        pr: 10,
        width: '100%'
    },
    invalidUserError: {
        color: 'red',
        fontSize: '12px'
    },
    button: {
        mt: 2,
        // px: 6,
        // py: 1.5,
        borderRadius: '4px',
        bgcolor: '#F46326',
        ":hover": {
            bgcolor: '#dc4d28'
        },
        width: '110px'
    },
    header: {
        backgroundColor: '#4b4b4b',
        p: 3,
        textAlign: 'center',
    },
    headerHeading: {
        pt: 2,
        color: 'white',
        fontSize: '12px',
    },
    poweredByBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        mt: 2
    },
    poweredByTypography: {
        color: '#212121',
        fontSize: '12px'
    },
    poweredByLogoBox: {
        mt: 1,
        mb: 4,
        display: 'flex',
        justifyContent: 'center'
    },
    footer: {
        backgroundColor: '#4b4b4b',
        p: 2,
        textAlign: 'center',
        color: 'white',
    },
    footerTypography: {
        fontSize: '12px'
    }
}

export default loginCardStyles