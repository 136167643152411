import { useEffect, useState } from 'react';
import { useTheme, ThemeProvider, CssBaseline, } from '@mui/material'
import LoginCard from './Components/LoginCard';
import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom';
import './App.css'
import '@fontsource/lato/300.css';
import '@fontsource/lato/400.css';
import '@fontsource/lato/700.css';
import GET_CSRF from './Utils/fetchCsrfToken';
import LoginCardOTP from './Components/LoginCardOTP';

const App = props => {
    const theme = useTheme()
    const [searchParams] = useSearchParams();
    const email = searchParams.get('email');

    const [csrfToken, setCsrfToken] = useState("")

    useEffect(() => {
        GET_CSRF(setCsrfToken)
    }, [])

    // useEffect(() => {
    //     // Check if the pathname contains anything other than '/'
    //     if ((location.pathname !== '/' || !email) && location.pathname !== '/vendor') {
    //         // Replace the URL with '/'
    //         window.history.replaceState({}, '', '/');
    //     }
    // }, [location, email]);

    const handleContextMenu = (e) => {
        e.preventDefault()
    }

    useEffect(() => {
        document.addEventListener('contextmenu', handleContextMenu)

        return () => {
            document.removeEventListener('contextmenu', handleContextMenu)  //After event handler is executed,remove it to prevent memeory leaks
        }
    }, [])

    //clickJacking prevention - frameBusting code
    useEffect(() => {
        if (window !== window.top) {
            window.top.location = window.location
        }
    }, [])


    return <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
            <Route path="/" element={<LoginCard loggedoutEmail={email} csrfToken={csrfToken} setCsrfToken={setCsrfToken} />} />
            <Route path='/vendor' element={<LoginCardOTP loggedoutEmail={email} csrfToken={csrfToken} setCsrfToken={setCsrfToken} />} />
            <Route path='/*' element={<Navigate replace to='/' />} />

        </Routes>

    </ThemeProvider >
}

export default App;
